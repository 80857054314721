<template>
  <div class="">
    <div class="container w-full mx-auto py-10 px-4 md:px-0">
      <div>
        <div>
          <ViewTitle :title="$t('compare.title')" />
          <ViewSubtitle :subtitle="$t('compare.subtitle')" />

          <div class="md:w-8/12">
            <div class="relative">
              <p class="mb-1 text-gray-500 dark:text-gray-400">
                {{ $t("compare.add_website_to_compare") }}:
              </p>

              <Search customAction @onSelected="onSelected" />
            </div>
          </div>

          <div v-if="anySites" class="mt-10">
            <div class="md:flex mb-5">
              <h4
                class="
                  font-bold
                  uppercase
                  text-xl text-gray-700
                  dark:text-white
                "
              >
                {{ $t("compare.comparing_sites") }}
              </h4>

              <div
                class="flex space-x-2 ml-auto mt-2 md:mt-0 text-center"
                v-if="isLoggedIn"
              >
                <router-link
                  :to="$i18nRoute({ name: 'comparisons' })"
                  class="
                    flex-1
                    md:flex-none
                    uppercase
                    bg-webmoney
                    text-white
                    px-5
                    py-2
                    rounded
                    hover:bg-blue-400 hover:text-white
                    shadow
                    text-sm
                  "
                >
                  {{ $t("compare.all_comparisons_button") }}
                </router-link>
                <button
                  v-if="!comparisonExist()"
                  @click="saveCurrentCompare"
                  class="
                    flex-1
                    md:flex-none
                    uppercase
                    bg-webmoney
                    text-white
                    px-5
                    py-2
                    rounded
                    hover:bg-blue-400 hover:text-white
                    shadow
                    text-sm
                  "
                >
                  {{ $t("compare.save_comparison_button") }}
                </button>
                <button
                  v-if="comparisonExist()"
                  @click="removeUserComparison(comparisonExist())"
                  class="
                    flex-1
                    md:flex-none
                    uppercase
                    text-sm
                    py-2
                    px-5
                    bg-red-500
                    text-white
                    rounded
                    shadow
                    hover:bg-red-400
                  "
                >
                  {{ $t("compare.remove_comparison_button") }}
                </button>
              </div>
            </div>

            <div class="md:flex md:space-x-4">
              <div
                class="flex-1 border dark:border-gray-700 rounded p-2 pr-4 mb-2"
                v-for="site in sitesSortedByAdvisorRank"
                :key="site.id"
              >
                <div>
                  <div>
                    <div class="flex">
                      <router-link
                        :to="
                          $i18nRoute({
                            name: 'site',
                            params: { domain: site.domain },
                          })
                        "
                      >
                        <div
                          v-if="site.icon"
                          class="inline-block w-10 align-middle mr-2"
                        >
                          <img
                            :src="root + site.icon"
                            class="max-w-full max-h-full border dark:border-gray-700 rounded"
                          />
                        </div>
                        {{ site.domain }}
                      </router-link>

                      <button
                        @click="removeSiteFromComparison(site.domain)"
                        class="ml-auto"
                      >
                        <x-icon size="1x"></x-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="anySites" class="mt-5">
            <div>
              <h4
                class="
                  font-bold
                  uppercase
                  text-xl text-gray-700
                  dark:text-white
                  mb-5
                "
              >
                {{ $t("compare.ratings") }}
              </h4>

              <div class="md:flex md:space-x-4">
                <div class="flex-1 mb-4">
                  <h5
                    class="
                      font-bold
                      uppercase
                      text-lg text-gray-700
                      dark:text-white
                      mb-5
                    "
                  >
                    {{ $t("shared.advisor_rank") }}
                  </h5>

                  <div
                    class="border-b dark:border-gray-700 py-3"
                    v-for="site in sitesSortedByAdvisorRank"
                    :key="site.id"
                  >
                    <div class="flex items-center">
                      <router-link
                        :to="
                          $i18nRoute({
                            name: 'site',
                            params: { domain: site.domain },
                          })
                        "
                      >
                        <div
                          v-if="site.icon"
                          class="inline-block w-10 align-middle mr-2"
                        >
                          <img
                            :src="root + site.icon"
                            class="max-w-full max-h-full border dark:border-gray-700 rounded"
                          />
                        </div>
                        {{ site.domain }}
                      </router-link>
                      <h6 class="ml-auto">{{ site.advisor_rank }}</h6>
                    </div>
                  </div>
                </div>

                <div class="flex-1 mb-4">
                  <h5
                    class="
                      font-bold
                      uppercase
                      text-lg text-gray-700
                      dark:text-white
                      mb-5
                    "
                  >
                    {{ $t("shared.webmoney_rank") }}
                  </h5>

                  <div
                    class="border-b dark:border-gray-700 py-3"
                    v-for="site in sitesSortedByWebmoneyRank"
                    :key="site.id"
                  >
                    <div class="flex items-center">
                      <router-link
                        :to="
                          $i18nRoute({
                            name: 'site',
                            params: { domain: site.domain },
                          })
                        "
                      >
                        <div
                          v-if="site.icon"
                          class="inline-block w-10 align-middle mr-2"
                        >
                          <img
                            :src="root + site.icon"
                            class="max-w-full max-h-full border dark:border-gray-700 rounded"
                          />
                        </div>
                        {{ site.domain }}
                      </router-link>

                      <h6 class="ml-auto">{{ site.webmoney_rank || "—" }}</h6>
                    </div>
                  </div>
                </div>

                <div class="flex-1 mb-4">
                  <h5
                    class="
                      font-bold
                      uppercase
                      text-lg text-gray-700
                      dark:text-white
                      mb-5
                    "
                  >
                    {{ $t("shared.users_rating") }}
                  </h5>

                  <div
                    class="border-b dark:border-gray-700 py-3"
                    v-for="site in sitesSortedByUserRating"
                    :key="site.id"
                  >
                    <div class="flex items-center">
                      <router-link
                        :to="
                          $i18nRoute({
                            name: 'site',
                            params: { domain: site.domain },
                          })
                        "
                      >
                        <div
                          v-if="site.icon"
                          class="inline-block w-10 align-middle mr-2"
                        >
                          <img
                            :src="root + site.icon"
                            class="max-w-full max-h-full border dark:border-gray-700 rounded"
                          />
                        </div>
                        {{ site.domain }}
                      </router-link>

                      <h6 class="ml-auto">
                        {{ site.average_feedbacks_point || "—" }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="anySites" class="mt-5">
            <div class="">
              <h4
                class="
                  font-bold
                  uppercase
                  text-xl text-gray-700
                  dark:text-white
                  mb-5
                "
              >
                {{ $t("compare.attendance") }}
              </h4>

              <div class="md:flex md:space-x-4">
                <div class="flex-1 md-4">
                  <h5
                    class="
                      font-bold
                      uppercase
                      text-lg text-gray-700
                      dark:text-white
                      mb-5
                    "
                  >
                    {{ $t("compare.visits") }}
                  </h5>

                  <div
                    v-for="(site, index) in sitesSortedByVisitsCount"
                    :key="site.id"
                    class="py-3"
                  >
                    <div class="">
                      <div class="flex items-center">
                        <span>
                          <router-link
                            :to="
                              $i18nRoute({
                                name: 'site',
                                params: { domain: site.domain },
                              })
                            "
                            class=""
                          >
                            <div
                              v-if="site.icon"
                              class="inline-block w-10 align-middle mr-2"
                            >
                              <img
                                :src="root + site.icon"
                                class="max-w-full max-h-full border dark:border-gray-700 rounded"
                              />
                            </div>
                            {{ site.domain }}
                          </router-link>
                        </span>
                        <span class="ml-auto">
                          {{ site.chart.visits_count }}
                        </span>
                      </div>
                    </div>

                    <div class="h-1 bg-gray-100 mt-4">
                      <div
                        class="h-1"
                        :class="{
                          'bg-blue-500': colors[index].name === 'blue',
                          'bg-yellow-500': colors[index].name === 'yellow',
                          'bg-red-500': colors[index].name === 'red',
                          'bg-pink-500': colors[index].name === 'pink',
                          'bg-purple-500': colors[index].name === 'purple',
                        }"
                        role="progressbar"
                        :style="{
                          width:
                            100 / (totalVisitsCount / site.chart.visits_count) +
                            '%',
                        }"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="flex-1 md-4">
                  <h5
                    class="
                      font-bold
                      uppercase
                      text-lg text-gray-700
                      dark:text-white
                      mb-5
                    "
                  >
                    {{ $t("compare.uniq_visits") }}
                  </h5>

                  <div
                    v-for="(site, index) in sitesSortedByUniqVisitsCount"
                    :key="site.id"
                    class="py-3"
                  >
                    <div class="">
                      <div class="flex items-center">
                        <span>
                          <router-link
                            :to="
                              $i18nRoute({
                                name: 'site',
                                params: { domain: site.domain },
                              })
                            "
                            class=""
                          >
                            <div
                              v-if="site.icon"
                              class="inline-block w-10 align-middle mr-2"
                            >
                              <img
                                :src="root + site.icon"
                                class="max-w-full max-h-full border dark:border-gray-700 rounded"
                              />
                            </div>
                            {{ site.domain }}
                          </router-link>
                        </span>
                        <span class="ml-auto">
                          {{ site.chart.uniq_visits_count }}
                        </span>
                      </div>
                    </div>

                    <div class="h-1 bg-gray-100 mt-4">
                      <div
                        class="h-1"
                        :class="{
                          'bg-blue-500': colors[index].name === 'blue',
                          'bg-yellow-500': colors[index].name === 'yellow',
                          'bg-red-500': colors[index].name === 'red',
                          'bg-pink-500': colors[index].name === 'pink',
                          'bg-purple-500': colors[index].name === 'purple',
                        }"
                        role="progressbar"
                        :style="{
                          width:
                            100 /
                              (totalUniqVisitsCount /
                                site.chart.uniq_visits_count) +
                            '%',
                        }"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-3">
                <div class="">
                  <h5
                    class="
                      font-bold
                      uppercase
                      text-lg text-gray-700
                      dark:text-white
                    "
                  >
                    {{ $t("compare.chart-title-1") }}
                  </h5>

                  <CompareCharts
                    :chartdatasets="chartData"
                    :chartlabels="chartLabels"
                    :height="100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import CompareCharts from "@/components/CompareCharts.vue";
import Search from "@/components/Search.vue";
import { XIcon } from "vue-feather-icons";
import ViewTitle from "@/components/ViewTitle.vue";
import ViewSubtitle from "@/components/ViewSubtitle.vue";

export default {
  components: {
    CompareCharts,
    Search,
    XIcon,
    ViewTitle,
    ViewSubtitle,
  },
  data() {
    return {
      query: "",
      root: process.env.VUE_APP_BACKEND_URL,
      colors: [
        { color: "#3B82F6", name: "blue" },
        { color: "#F59E0B", name: "yellow" },
        { color: "#EF4444", name: "red" },
        { color: "#EC4899", name: "pink" },
        { color: "#8B5CF6", name: "purple" },
      ],
    };
  },
  created() {
    this.parseSitesFromString();
    this.getUserComparisons();
  },
  watch: {
    $route: ["parseSitesFromString", "getUserComparisons"],
  },
  computed: mapState({
    sites: (state) => [{ data: state.search.sites }],

    chartData: function () {
      return [...this.sitesSortedByVisitsCount].map((site, index) => {
        return {
          label: site.domain,
          showLine: true,
          fill: false,
          data: site.chart.data,
          backgroundColor: this.colors[index].color,
          borderColor: this.colors[index].color,
          pointHoverBorderColor: "#3498db",
          pointRadius: 5,
          pointHoverRadius: 5,
          pointBorderWidth: 2,
          pointHoverBorderWidth: 2,
          borderWidth: 2,
          pointBackgroundColor: this.colors[index].color,
          pointHoverBackgroundColor: "#3498db",
          steppedLine: "middle",
        };
      });
    },
    chartLabels: function () {
      return [...this.sitesSortedByVisitsCount][0].chart.labels;
    },
    comparisons: (state) => state.cabinet.comparisons.list,
    ...mapGetters({
      anySites: "compare/anySites",
      sitesSortedByAdvisorRank: "compare/sitesSortedByAdvisorRank",
      sitesSortedByWebmoneyRank: "compare/sitesSortedByWebmoneyRank",
      sitesSortedByUserRating: "compare/sitesSortedByUserRating",
      sitesSortedByVisitsCount: "compare/sitesSortedByVisitsCount",
      sitesSortedByUniqVisitsCount: "compare/sitesSortedByUniqVisitsCount",
      totalVisitsCount: "compare/totalVisitsCount",
      totalUniqVisitsCount: "compare/totalUniqVisitsCount",
      isLoggedIn: "auth/isLoggedIn",
    }),
  }),
  methods: {
    onSelected(domain) {
      var sitesString = this.$route.params.sites;
      if (sitesString && sitesString.length > 0) {
        let list = sitesString.split(",", 5).filter((i) => i !== domain);
        list.push(domain);
        this.$router.push({
          name: "compare-sites",
          params: { sites: list.join(",") },
        });
      } else {
        this.$router.push({
          name: "compare-sites",
          params: { sites: domain },
        });
      }
    },

    parseSitesFromString() {
      var sitesString = this.$route.params.sites;
      if (sitesString && sitesString.length > 0) {
        this.$store.dispatch(
          "compare/setCompareList",
          sitesString.split(",", 5)
        );
      } else {
        this.$store.dispatch("compare/setCompareList", []);
      }
    },
    saveCurrentCompare() {
      this.$store.dispatch(
        "cabinet/comparisons/addUserComparison",
        this.sitesSortedByAdvisorRank.map((site) => site.domain)
      );
    },
    getUserComparisons() {
      if (this.isLoggedIn) {
        this.$store.dispatch("cabinet/comparisons/getUserComparisons");
      }
    },
    removeUserComparison(id) {
      if (this.isLoggedIn) {
        this.$store.dispatch("cabinet/comparisons/removeUserComparison", id);
      }
    },
    comparisonExist() {
      let id = null;
      var sitesString = this.$route.params.sites;
      if (sitesString && sitesString.length > 0) {
        this.comparisons.map((comparison) => {
          let sites = comparison.sites.map((site) => {
            return site.domain;
          });
          if (this.compareArrays(sites, sitesString.split(",", 5))) {
            id = comparison.id;
          }
        });
      }
      return id;
    },
    compareArrays(arrA, arrB) {
      if (arrA.length !== arrB.length) return false;
      var cA = arrA.slice().sort().join(",");
      var cB = arrB.slice().sort().join(",");
      return cA === cB;
    },
    removeSiteFromComparison(site) {
      var sitesString = this.$route.params.sites;
      let list = sitesString.split(",", 5).filter((item) => item !== site);
      if (list.length > 0) {
        this.$router.push({
          name: "compare-sites",
          params: { sites: list.join(",") },
        });
      } else {
        this.$router.push({ name: "compare" });
      }
    },
  },
};
</script>
