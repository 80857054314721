<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ["chartdatasets", "chartlabels"],
  computed: {
    chartData: function () {
      return this.chartdatasets;
    },
  },
  methods: {
    renderLineChart: function () {
      this.renderChart(
        {
          labels: this.chartlabels,
          datasets: this.chartData,
        },
        {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: false,
                },
              },
            ],
          },
        }
      );
    },
  },
  watch: {
    chartdatasets: function () {
      this.$data._chart.destroy();
      this.renderLineChart();
    },
  },
  mounted() {
    this.renderLineChart();
  },
};
</script>
